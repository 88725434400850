/**
 * This Context provides methods to be used in your views. It also
 * share the state of the user, providing `isAuthenticated` flag
 * to indicate if there is a logged in user.
 */
import React, { useState, useMemo, useCallback, createContext } from 'react';
import PropTypes from 'prop-types';
import {
  loginByCredentials,
  validateUser,
  loginByPairingCode,
  logout as logoutService,
  getPairingCode as getPairingCodeService
} from '#/services/idp';

import { invalidateCache } from '#/services';

/**
 * Login by credentials definition
 * @typedef {Function} LoginByCredentials
 * @property {String} username
 * @property {String} password
 */

/**
 * Login by pairing code
 * @typedef {Function} LoginByPairingCode
 */

/**
 * Definition of login alternatives used by this template
 * @typedef {Object} LoginAlternatives
 * @property {LoginByCredentials} byCredentials
 * @property {LoginByPairingCode} byPairingCode
 */

const AuthContext = createContext({
  /**
   * Indicate if there is an authenticated user
   * @type {Boolean}
   */
  isAuthenticated: false,
  /**
   * Login alternatives used by this template
   * @returns {LoginAlternatives} Login alternatives
   */
  login: () => {},
  /**
   * Logout user
   * @returns {Promise<Boolean>} Logout success
   */
  logout: () => {},
  /**
   * @returns {Promise<User>} User logged
   */
  loginStartup: () => {},

  /**
   * Get the current logged user
   * @returns {User} Logged user
   */
  getUser: () => {},

  /**
   * Get a code to pair the device with a specific user account
   * @param {String} deviceId Device to pair
   * @returns {Promise<String>} Pairing code
   */
  getPairingCode: async deviceId => Promise.resolve(deviceId),
  /**
   * Checks the Authentication status when the app starts
   * @returns {Promise<Object>} user Object or empty object
   */
  checkAuthStatus: async () => {}
});

/**
 * Auth Context Provider
 * @param {Object} props Context Props
 * @param {React.ReactNode} props.children Context Props
 * @returns {React.Context} Auth Context
 */
const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggedUser, setLoggedUser] = useState({
    username: null
  });

  /**
   * Get the current logged user
   * @returns {User} Current logged user
   */
  const getUser = useCallback(() => loggedUser, [loggedUser]);

  /**
   * Login alternatives used by this template
   * @property {Function} byCredentials
   * @returns {LoginAlternatives} Login alternatives
   */
  const login = useCallback(() => {
    /**
     * Login user by credentials
     * @param {String} username Username
     * @param {String} password Password
     * @returns {Promise<User>} Loggedin User
     */
    const byCredentials = async (username, password) => {
      return loginByCredentials(username, password).then(user => {
        setLoggedUser(user);
        setIsAuthenticated(true);
        invalidateCache();
      });
    };

    /**
     * Login user by pairing code
     * @param {String} deviceId Device to pair
     * @param {AbortController} abortController Controller to abort the request if needed
     * @returns {Promise<void>} Void
     */
    const byPairingCode = async (deviceId, abortController) =>
      loginByPairingCode(deviceId, abortController).then(user => {
        if (user) {
          setLoggedUser(user);
          setIsAuthenticated(true);
          invalidateCache();
        }
      });
    return { byCredentials, byPairingCode };
  }, []);

  // SONY - QUICKPLAY POC
  // Autologin feature
  const loginStartup = useCallback(() => {
    return loginByCredentials('foo', 'bar').then(user => {
      if (user) {
        setLoggedUser(user);
        setIsAuthenticated(true);
        invalidateCache();
        return user;
      }
    });
  });

  /**
   * Get a code to pair the device with a specific user account
   * @param {String} deviceId Device to pair
   * @returns {Promise<String>} Pairing code
   */
  const getPairingCode = useCallback(
    async deviceId =>
      getPairingCodeService(deviceId).then(result => result.code),
    []
  );

  /**
   * Logout user
   * @returns {Promise<Boolean>} Logout success
   */
  const logout = useCallback(async () => {
    return logoutService().then(response => {
      setLoggedUser(null);
      setIsAuthenticated(false);
      invalidateCache();
      return response;
    });
  }, []);

  /**
   * Check if a user is logged in and update the app state
   * @returns {Promise<Object>} User
   */
  const checkAuthStatus = useCallback(async () => {
    try {
      const user = await validateUser();
      if (user) {
        setLoggedUser(user);
        setIsAuthenticated(true);
      }
      return user;
    } catch (e) {
      // TODO: we need to make a refresh of the token, but IDP is not ready
      logout();
      return { error: e };
    }
  }, [logout]);

  const contextValue = useMemo(
    () => ({
      login,
      logout,
      loginStartup,
      getUser,
      isAuthenticated,
      getPairingCode,
      checkAuthStatus
    }),
    [
      login,
      logout,
      loginStartup,
      getUser,
      isAuthenticated,
      getPairingCode,
      checkAuthStatus
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };

AuthContextProvider.propTypes = {
  children: PropTypes.node
};
