import { useContext, useCallback } from 'react';
import { ConfigContext, ConfigSetcontext } from '#/context/ConfigContext';
import useAuthProfile from '#/hooks/useAuthProfile';
import {
  ConfigExtendedHook,
  ConfigExtended
} from '#/interfaces/ConfigExtended';
import { getConfiguration } from '#/services/config';
import { getDefaultTheme, getRoutes } from '#/services/cms';
import { getSegmentationValue } from '#/utils/segmentationUtils';
import {
  getLiveProgramByChannelId as getProgram,
  getTvListingsQuickplay
} from '#/services/ovp/live';
import { authLivePlayback, authVodPlayback, getToken } from '#/utils/quickplay';
import { QuickplayConfig } from '#/interfaces/quickplay';

let configRequestPending = false;

const useAppConfig = (): ConfigExtendedHook => {
  const configContext = useContext(ConfigContext);
  const authContext = useAuthProfile();
  const { updateConfig } = useContext(ConfigSetcontext);

  if (updateConfig === undefined || configContext === undefined) {
    throw new Error('useAppConfig must be used within a ConfigProvider');
  }

  const getConfigData = useCallback(
    async (profileContextParam: typeof authContext) => {
      if (configRequestPending || profileContextParam.currentProfileLoading) {
        return;
      }
      configRequestPending = true;
      let appConfig: ConfigExtended;
      try {
        const segmentationValue = getSegmentationValue(
          profileContextParam?.currentProfile || undefined
        );
        appConfig = (await getConfiguration(
          segmentationValue
        )) as ConfigExtended;
        const [firstPromise, secondPromise] = await Promise.allSettled([
          getDefaultTheme(segmentationValue),
          getRoutes(segmentationValue)
        ]);
        const { value: theme } = firstPromise as PromiseFulfilledResult<any>;
        const {
          value: routesMapping
        } = secondPromise as PromiseFulfilledResult<any[]>;
        appConfig.theme = theme;
        appConfig.routes = routesMapping;
        configRequestPending = false;

        const quickplayConfig: QuickplayConfig =
          appConfig.providers.ovp.quickplay;
        const {
          epgDataUrl,
          xClientId,
          deviceName,
          deviceId,
          authorizeContentUrl
          // clientId,
          // clientSecret,
          // oauthTokenUrl,
          // accessTokenUrl,
          // epgChannelsUrl
        } = quickplayConfig;

        const epgConfig = {
          requestUrl: epgDataUrl,
          xClientId,
          deviceName
        } as const;

        const getAccessToken = () => getToken('access', quickplayConfig);
        const getOauthToken = () => getToken('oauth', quickplayConfig);

        appConfig.quickplay = {
          getAccessToken,
          getOauthToken,
          getTvListings: args =>
            getTvListingsQuickplay({ ...args, config: epgConfig }),
          getLiveProgramByChannelId: (channelId: string) =>
            getProgram(channelId, epgConfig),
          getLiveStream: async contentId => {
            return authLivePlayback({
              requestUrl: authorizeContentUrl,
              oauthToken: await getOauthToken(),
              accessToken: await getAccessToken(),
              xClientId,
              deviceName,
              contentId,
              deviceId
            });
          },
          getVodStream: async contentId => {
            return authVodPlayback({
              requestUrl: authorizeContentUrl,
              oauthToken: await getOauthToken(),
              accessToken: await getAccessToken(),
              xClientId,
              deviceName,
              contentId,
              deviceId
            });
          }
        };
        updateConfig({ config: appConfig, requireConfigUpdate: false });
        // Call get access token to cache token request
        getAccessToken();

        return appConfig;
      } catch (error) {
        const config = { errorLoading: true };
        updateConfig({ config, requireConfigUpdate: false });
        return config;
      }
    },
    [updateConfig]
  );

  const getConfig = useCallback(() => {
    const { config, requireConfigUpdate } = configContext;
    if (!config || requireConfigUpdate) {
      getConfigData(authContext);
      return {};
    }
    return config;
  }, [configContext, getConfigData, authContext]);

  return {
    config: getConfig()
  };
};

export default useAppConfig;
