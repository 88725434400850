import React, {
  useMemo,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect
} from 'react';
import PropTypes from 'prop-types';

import logger from '#/utils/logger';
import { getItemsByQuery } from '#/services/ovp';
import {
  ACCEDO_CONTROL_CONTAINER_TEMPLATES,
  CONTAINER_ID_TEMPLATE_MAP,
  CONTAINER_ITEM_TYPES,
  DEFAULT_TEMPLATE,
  ROUTES
} from '#/config/constants';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import { AuthContext } from '#/context/AuthContext';
import { ProfileContext } from '#/context/ProfileContext';
import { getSegmentationValue } from '#/utils/segmentationUtils';
import {
  CONTAINER_ID_TO_COMPONENT_MAP,
  determineItemTypeByTemplate,
  determineAligment
} from './templatesMapper';

const getItemOnClickFn = ({ historyPush }) => {
  return item => {
    const { type, viewAll, query, displayText, link, id } = item;
    const isContinueWatching = type === CONTAINER_ITEM_TYPES.Bookmark;
    const isCharacter = type === CONTAINER_ITEM_TYPES.Character;
    const isLive = type === CONTAINER_ITEM_TYPES.Live;

    if (isCharacter) {
      logger.debug('Redirect to: character modular ui');
      historyPush({
        path: `${ROUTES.collection}/${id}`,
        state: {
          asset: item
        }
      });
    } else if (isLive) {
      const { curProgram } = item;
      logger.debug('Redirect to: player');
      historyPush({
        path: ROUTES.player,
        state: {
          asset: curProgram,
          isLive: true,
          videoUrl: curProgram.videoUrl
        }
      });
    } else if (isContinueWatching) {
      logger.debug('Redirect to: player');
      historyPush({
        path: ROUTES.player,
        state: {
          asset: item,
          resumeTime: item.resumeTime,
          episodes: item?.seasonEpisodes,
          seasons: item?.seriesSeasons,
          videoUrl: item.videoUrl
        }
      });
    } else if (viewAll) {
      // view all
      historyPush({
        path: ROUTES.viewAll,
        state: {
          query,
          displayText,
          pageTitle: displayText
        }
      });
    } else {
      logger.debug(`Redirect to: ${link}`);
      historyPush({
        path: link,
        ...(item.quickplay && { state: { asset: item } })
      });
    }
  };
};

const Shelf = ({
  contextData,
  items: itemFromProps,
  query,
  template,
  config,
  nav,
  removeEmptyContainers
}) => {
  const historyPush = useHistoryPush();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadItems = useCallback(() => {
    if (itemFromProps?.length) {
      return setItems(itemFromProps);
    }

    if (isLoading) {
      const fakeItem = { title: 'Loading...', images: [{ url: '' }] };
      return setItems(Array(3).fill(fakeItem));
    }

    setItems(null);
  }, [isLoading, itemFromProps]);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  if (!CONTAINER_ID_TEMPLATE_MAP[template]) {
    logger.warn(`Template "${template}" is not supported`);
  }

  const containerId =
    CONTAINER_ID_TEMPLATE_MAP[template] ||
    CONTAINER_ID_TEMPLATE_MAP[DEFAULT_TEMPLATE];

  const ShelfComponent =
    CONTAINER_ID_TO_COMPONENT_MAP[containerId] ||
    CONTAINER_ID_TO_COMPONENT_MAP.Default;

  const itemType = determineItemTypeByTemplate(template);
  const aligment = determineAligment(template);

  const authContext = useContext(AuthContext);
  const profileContext = useContext(ProfileContext);
  const userId = authContext.getUser()?.userId;

  const dataSource = useMemo(() => {
    let totalNumber;
    const hasViewAll =
      template === ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselViewAllPortrait;

    // The userId is needed to get the Jump recommendations
    const contextDataUpdated = { ...contextData, userId };

    return {
      hasData: async () => {
        return !!totalNumber;
      },
      isPaginationAllowed: async () => {
        return true;
      },
      getTotalNumber: async () => {
        return totalNumber;
      },
      getData: async (page, itemsPerPage) => {
        const { items: result, total } = await getItemsByQuery({
          query,
          template,
          contextData: contextDataUpdated,
          pageNumber: page,
          itemsPerPage,
          segmentationValue: getSegmentationValue(
            profileContext?.currentProfile
          )
        });
        totalNumber = total;

        setIsLoading(false);

        // put 'view all' button as the first element of the carousel
        if (hasViewAll && page === 1) {
          if (result) {
            const itemToPush = {
              viewAll: true,
              query,
              displayText: config.displayText,
              id: `${template}-${Math.random()}`
            };
            result?.unshift(itemToPush);
          }
        }

        if (!result?.length && page <= 1) {
          removeEmptyContainers();
        }

        return {
          data: result,
          total: totalNumber
        };
      }
    };
  }, [
    template,
    userId,
    contextData,
    query,
    config.displayText,
    removeEmptyContainers,
    profileContext
  ]);

  return (
    <ShelfComponent
      ds={dataSource}
      items={items}
      contextData={contextData}
      itemType={itemType}
      gridAlignment={aligment}
      config={config}
      onClick={getItemOnClickFn({
        historyPush
      })}
      nav={nav}
      query={config.query}
    />
  );
};

Shelf.propTypes = {
  config: PropTypes.object,
  contextData: PropTypes.object,
  nav: PropTypes.object,
  items: PropTypes.array,
  query: PropTypes.string,
  template: PropTypes.string,
  removeEmptyContainers: PropTypes.func
};

export default Shelf;
