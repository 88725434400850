import accedoOne from '@accedo/accedo-one';

import appConfig from '#/config/app';
import { APPID_STORE_KEY, GID_STORE_KEY } from '#/config/constants';

/**
 * Helper to create an accedo sdk singleton with the expected configuration
 *
 * @link: https://one.accedo.tv/control/application-group/575635cb9c9dad01e7b67dc4/application/57563c1b9c9dad01e7b696c5 - VDKCTV  - VDKREFERENCE - VDKCTV
 *
 */

/**
 * Constant key to store the session values
 */
export const SESSION_KEY = 'a1_s';

let accedoOneClientInstance;
let accedoOneClientFromGid;

const importAndLoadXdk = async () => {
  const { default: XDK } = await import('@accedo/xdk-core');
  return XDK.load();
};

const getControlClient = async segmentationValue => {
  const xdkLoaded = await importAndLoadXdk();
  const xdkStorage = await xdkLoaded.getStorageManager().getStorage();
  const deviceId = await xdkLoaded.getSystem().getUniqueId();
  const sessionKey = await xdkStorage.get(SESSION_KEY);

  const storedApplicationId = xdkStorage.get(APPID_STORE_KEY);
  const storedGid = xdkStorage.get(GID_STORE_KEY);
  const gid = storedGid || segmentationValue;
  if (accedoOneClientInstance && accedoOneClientFromGid === gid) {
    return accedoOneClientInstance;
  }
  const controlConfig = {
    appKey:
      storedApplicationId ||
      process.env.ACCEDO_ONE_KEY ||
      appConfig.controlAppId,
    deviceId,
    gid,
    sessionKey, // Can be undefined. First time will be undefined and will generate a new one within @accedo/accedo-one
    onSessionKeyChanged: key => {
      xdkStorage.set(SESSION_KEY, key);
    }
  };

  accedoOneClientInstance = accedoOne(controlConfig);

  return accedoOneClientInstance;
};

export const getControlClientSingleton = getControlClient;
