/**
 * @module models/CMS
 */

/**
 * This model represents the Theme.
 *
 * vikiElevateTheme representation
 * @typedef {Object} VikiElevateTheme
 * @property {String} accent focus color
 * @property {String} alternativeBackground skeletons, other backgrounds
 * @property {String} background page background
 * @property {String} colorOnAccent text color on focus
 * @property {String} colorOnBackground main color for regular text
 * @property {String} colorOnAccent error text color
 * @property {String} overlay overlay color
 */

/**
 * VikiElevateTheme Model
 * @param {Object} rawTheme Theme
 * @returns {VikiElevateTheme} theme - The {@link VikiElevateTheme} representation
 */
const theme = rawTheme => {
  const {
    accent,
    alternativeBackground,
    background,
    colorOnAccent,
    colorOnBackground,
    error,
    primary,
    textPrimary,
    disabled
  } = rawTheme;

  const getAcent = () => {
    return accent;
  };

  const getAlternativeBackground = () => {
    return alternativeBackground;
  };

  const getBackground = () => {
    return background;
  };

  const getColorOnAccent = () => {
    return colorOnAccent;
  };

  const getColorOnBackground = () => {
    return colorOnBackground;
  };

  const getError = () => {
    return error;
  };

  const getPrimary = () => {
    return primary;
  };

  const getTextPrimary = () => {
    return textPrimary;
  };

  const getDisabled = () => {
    return disabled;
  };

  return {
    accent: getAcent(),
    alternativeBackground: getAlternativeBackground(),
    background: getBackground(),
    colorOnAccent: getColorOnAccent(),
    colorOnBackground: getColorOnBackground(),
    error: getError(),
    primary: getPrimary(),
    textPrimary: getTextPrimary(),
    disabled: getDisabled()
  };
};

export default theme;
