import { useContext } from 'react';
import { ProfileContext } from '#/context/ProfileContext';
import {
  createProfile,
  getProfiles,
  getProfile,
  getAvatars,
  updateProfileAvatar,
  updateProfileName,
  deleteProfile,
  createPIN,
  deletePIN,
  updatePIN
} from '#/services/idp';

const useProfile = () => {
  const { currentProfile, setCurrentProfile, clearCurrentProfile } = useContext(
    ProfileContext
  );

  return {
    currentProfile,
    setCurrentProfile,
    clearCurrentProfile,
    createProfile,
    getProfiles,
    getProfile,
    getAvatars,
    updateProfileAvatar,
    updateProfileName,
    deleteProfile,
    createPIN,
    deletePIN,
    updatePIN
  };
};

export default useProfile;
