import { SHELF_CONTAINER_IDS, ITEM_TYPES } from '#/config/constants';

import CarouselShelf from './CarouselShelf/CarouselShelf';
import CarouselQuickplayShelf from './CarouselQuickplayShelf/CarouselQuickplayShelf';
import GridShelf from './GridShelf/GridShelf';
import HeroShelf from './HeroShelf/HeroShelf';
import ChannelBannerShelf from './ChannelBannerShelf/ChannelBannerShelf';

import GridSkeleton from '../Skeletons/GridSkeleton';
import HeroSkeleton from '../Skeletons/HeroSkeleton';

const ALIGNMENTS = {
  VERTICAL: 'grid-align-vertical',
  HORIZONTAL: 'grid-align-horizontal'
};

// TODO: update based on the constants on ACCEDO_CONTROL_CONTAINER_TEMPLATES
const determineItemTypeByTemplate = containerTemplate => {
  if (containerTemplate.includes('quickplay')) {
    if (containerTemplate.includes('portrait')) {
      return ITEM_TYPES.Portrait;
    }
    if (containerTemplate.includes('wide')) {
      return ITEM_TYPES.Wide;
    }
  }

  if (containerTemplate.includes('continue-watching')) {
    return ITEM_TYPES.Bookmark;
  }

  if (containerTemplate.includes('carousel-categories')) {
    return ITEM_TYPES.Categories;
  }

  if (containerTemplate.includes('carousel-category-portrait')) {
    return ITEM_TYPES.Categories;
  }

  if (containerTemplate.includes('grid-portrait')) {
    return ITEM_TYPES.Portrait;
  }

  if (containerTemplate.includes('featured-carousel-portrait')) {
    return ITEM_TYPES.FeaturedCarouselPortrait;
  }

  if (containerTemplate.includes('portrait')) {
    return ITEM_TYPES.Portrait;
  }

  if (containerTemplate.includes('landscape')) {
    return ITEM_TYPES.Landscape;
  }

  if (containerTemplate.includes('grid-vertical-wide')) {
    return ITEM_TYPES.GridLandscape;
  }

  if (containerTemplate.includes('wide')) {
    return ITEM_TYPES.Wide;
  }

  if (containerTemplate.includes('character')) {
    return ITEM_TYPES.Character;
  }

  return ITEM_TYPES.Square;
};

const determineAligment = template => {
  if (template === 'grid-portrait' || template.includes('vertical')) {
    return ALIGNMENTS.VERTICAL;
  }
  return ALIGNMENTS.HORIZONTAL;
};

const CONTAINER_ID_TO_COMPONENT_MAP = {
  [SHELF_CONTAINER_IDS.Carousel]: CarouselShelf,
  [SHELF_CONTAINER_IDS.Default]: GridShelf,
  [SHELF_CONTAINER_IDS.Grid]: GridShelf,
  [SHELF_CONTAINER_IDS.HeroBanner]: HeroShelf,
  [SHELF_CONTAINER_IDS.ChannelBanner]: ChannelBannerShelf,
  [SHELF_CONTAINER_IDS.CharacterCarousel]: CarouselShelf,
  [SHELF_CONTAINER_IDS.CarouselQuickplay]: CarouselQuickplayShelf
};

const CONTAINER_ID_TO_SKELETON_COMPONENT_MAP = {
  [SHELF_CONTAINER_IDS.Carousel]: GridSkeleton,
  [SHELF_CONTAINER_IDS.Default]: GridSkeleton,
  [SHELF_CONTAINER_IDS.Grid]: GridSkeleton,
  [SHELF_CONTAINER_IDS.HeroBanner]: HeroSkeleton
};

const isContianerIDCarousel = containerId =>
  containerId === SHELF_CONTAINER_IDS.Carousel;

export {
  determineItemTypeByTemplate,
  determineAligment,
  isContianerIDCarousel,
  CONTAINER_ID_TO_COMPONENT_MAP,
  CONTAINER_ID_TO_SKELETON_COMPONENT_MAP
};
