import { ACCEDO_CONTROL_CONTAINER_TEMPLATES } from '#/config/constants';
import { Image } from '#/interfaces/Image';
import {
  AccessTokenResponse,
  AuthorizationPlaybackPayload,
  AuthorizationPlaybackResponse,
  Channel,
  OAuthResponse,
  Program,
  QuickPlayMovie,
  QuickPlayMovieFeed,
  QuickPlayMovieItem,
  QuickplayConfig
} from '#/interfaces/quickplay';

const ONE_MINUTE = 60 * 1000;
const IMG_FORMATS = {
  F1x1: '0-1x1',
  F3x1: '0-3x1',
  F2x3: '0-2x3',
  F16x9: '0-16x9'
};

export interface TokenResponse {
  token: string;
  expiresIn: number;
}

export const fetchOAuthToken = async ({
  requestUrl,
  clientId,
  clientSecret
}: {
  requestUrl: string;
  clientId: string;
  clientSecret: string;
}): Promise<TokenResponse> => {
  const url = `${requestUrl}?client_id=${clientId}&client_secret=${clientSecret}&grant_type=client_credentials&audience=edge-service&scope=openid`;

  const response = await fetch<OAuthResponse>(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  return response.json().then(tokenResponse => ({
    token: tokenResponse.access_token,
    expiresIn: tokenResponse.expires_in
  }));
};

export const fetchAccessToken = async ({
  requestUrl,
  oauthToken,
  xClientId,
  deviceName,
  deviceId
}: {
  requestUrl: string;
  oauthToken: string;
  xClientId: string;
  deviceName: string;
  deviceId?: string;
}): Promise<TokenResponse> => {
  const response = await fetch<AccessTokenResponse>(requestUrl, {
    method: 'POST',
    headers: {
      'X-Client-Id': xClientId,
      Authorization: `Bearer ${oauthToken}`,
      'Content-Type': 'text/plain'
    },
    body: JSON.stringify({ deviceId, deviceName })
  });

  return response.json().then(tokenResponse => ({
    token: tokenResponse.data.token,
    expiresIn: tokenResponse.data.expires_in
  }));
};

const tokenCache: Record<
  'oauth' | 'access',
  {
    currentToken: string;
    expiresIn: number;
    inProcess?: Promise<TokenResponse> | null;
    fetcher: (config: QuickplayConfig) => Promise<TokenResponse>;
  }
> = {
  oauth: {
    currentToken: '',
    expiresIn: Date.now(),
    fetcher: config =>
      fetchOAuthToken({
        requestUrl: config.oauthTokenUrl,
        clientId: config.clientId,
        clientSecret: config.clientSecret
      }),
    inProcess: null
  },
  access: {
    currentToken: '',
    expiresIn: Date.now(),
    fetcher: async config => {
      // eslint-disable-next-line no-use-before-define
      const oauthToken = await getToken('oauth', config);

      return fetchAccessToken({
        requestUrl: config.accessTokenUrl,
        xClientId: config.xClientId,
        deviceName: config.deviceName,
        deviceId: config.deviceId,
        oauthToken
      });
    },
    inProcess: null
  }
};

export const getToken = async (
  type: 'oauth' | 'access',
  config: QuickplayConfig
): Promise<string> => {
  const { currentToken, expiresIn, inProcess, fetcher } = tokenCache[type];
  const requestTime = Date.now();

  if (currentToken && expiresIn - ONE_MINUTE > requestTime) {
    return currentToken;
  }

  if (inProcess) {
    return inProcess.then(token => {
      return token.token;
    });
  }

  const fetchRequestPromise = fetcher(config);
  tokenCache[type].inProcess = fetchRequestPromise;
  const tokenResponse = await fetchRequestPromise;
  tokenCache[type].inProcess = null;

  // Update cached token
  tokenCache[type].currentToken = tokenResponse.token;
  tokenCache[type].expiresIn = requestTime + tokenResponse.expiresIn * 1000;

  return tokenResponse.token;
};

export const getImageUrl = (
  resourceId: string,
  // NOTE: This file has been added to .prettierignore
  // as version of prettier used in this project doesn't support
  // types with template strings
  // / eslint-disable-next-line prettier/prettier
  aspectRatio: string, // `${number}x${number}` or `${number}-${number}x${number}`,
  // NOTE: Only 1 key of both needs to be provided. Either width or height.
  // For safety, width will take presendence over height if function gets called
  // with both
  size: RequireOnlyOne<{ width: number; height: number }>
) => {
  const { width, height } = size;
  const requestWidth = width != null ? `width=${width}` : '';
  const requestHeight =
    !requestWidth && height != null ? `height=${height}` : '';

  if (!requestWidth && !requestHeight) {
    console.warn('[Quickplay] Image request requires either width or height!');
  }

  const finalAspectRatio = aspectRatio.includes('0-')
    ? aspectRatio
    : `0-${aspectRatio}`;

  return `https://image-resizer-cloud-cdn.azureedge.net/image/${resourceId}/${finalAspectRatio}.jpg?${requestWidth}${requestHeight}`;
};

// POC: Fix time for fetching to get a consistent set of data or change it false to use current date.
// Reference time: Sunday Feb 19th
// const referenceDate = '2024-02-19T20:00:00.000Z' as const;
// const referenceDate = '2024-02-27T16:41:44.226Z' as const;
const referenceDate = false;

export const getDateWithAdjustedTime = (refTime: Date): Date => {
  if (!referenceDate) {
    return refTime;
  }

  const updatedDate = new Date(referenceDate);
  // If updating days in the same week
  // updatedDate.setDate(updatedDate.getDate() + refTime.getDay());
  // If using same day for every day
  updatedDate.setDate(updatedDate.getDate());

  updatedDate.setHours(refTime.getHours());
  updatedDate.setMinutes(refTime.getMinutes());
  updatedDate.setSeconds(refTime.getSeconds());
  updatedDate.setMilliseconds(refTime.getMilliseconds());
  return updatedDate;
};

const mapToCurrentTime = (
  time: Date | string | number,
  refTime?: Date
): number => {
  if (!refTime || !referenceDate) {
    return new Date(time).getTime();
  }

  const updatedDate = new Date(time);
  updatedDate.setDate(refTime.getDate());
  updatedDate.setMonth(refTime.getMonth());
  // updatedDate.setFullYear(refTime.getFullYear);
  return updatedDate.getTime();
};

const mapQuickplayEpgData = (
  epgData: { data: Channel[] },
  dateRefs: { startDate: Date; endDate: Date } = {} as any
) => {
  const data = epgData.data || [];
  if (!data.length) {
    console.warn('[QUICKPLAY] Epg returned no data!!!', dateRefs);
  }

  const normalizedEpgData = data.map((channel, idx: number) => {
    return {
      title: channel.cs,
      channelId: channel.cid,
      id: channel.cid,
      channelNumber: idx + 1,
      description: '',
      images: [
        // Hardcoded logo image
        // TODO: update when available from storefront with Amagi and Frequency
        {
          type: 'logo',
          width: 90,
          height: 90,
          url: getImageUrl(channel.cid, IMG_FORMATS.F1x1, { height: 200 })
        }
      ],
      categories: [null],
      language: 'en',
      programs: channel.airing.map((pr: Program) => {
        return {
          id: pr.id,
          images: [],
          type: pr.pgm.pgm_ty,
          channelId: pr.cid,
          mediaId: pr.pgm_id,
          title: pr.pgm.lon[0].n,
          description: pr.pgm.lod?.[0]?.n,
          videoUrl: '',
          // endTime: new Date(pr.sc_ed_dt).getTime(),
          // startTime: new Date(pr.sc_st_dt).getTime()
          startTime: mapToCurrentTime(pr.sc_st_dt, dateRefs?.startDate),
          endTime: mapToCurrentTime(pr.sc_ed_dt, dateRefs.endDate)
        };
      }),
      metadata: [],
      contents: [
        // Hardcoded data
        // TODO: Remove when verifying that it is not needed.
        // https://docs.google.com/document/d/13yfSb8RpWq8hVd7pk4xpWPcZ_QVTyTEVinsoQelA_io/edit#heading=h.mfvrhbp4ymxc
        {
          duration: 0,
          format: 'DASH',
          geoLock: false,
          height: 665,
          id: 'vid_104',
          language: 'en',
          url:
            'https://moctobpltc-i.akamaihd.net/hls/live/571329/eight/playlist.m3u8',
          width: 1182,
          _id: 'vid_104'
        }
      ]
    };
  });

  return normalizedEpgData;
};

export const getEPGData = ({
  startTime,
  endTime,
  config,
  channelId
}: {
  startTime: number;
  endTime: number;
  config: {
    requestUrl: string;
    xClientId: string;
    deviceName: string;
  };
  channelId?: string;
}): Promise<ReturnType<typeof mapQuickplayEpgData> | void> => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  } as const;

  // POC: Adjust time to get consistent data
  const tempStartDate = new Date(startTime);
  const tempEndDate = new Date(endTime);
  const startDate = getDateWithAdjustedTime(tempStartDate);
  const endDate = getDateWithAdjustedTime(tempEndDate);

  const { requestUrl, xClientId, deviceName } = config;

  return fetch<{ data: Channel[] }>(
    `${requestUrl}?reg=us&dt=${deviceName}&client=${xClientId}&start=${startDate.toISOString()}&end=${endDate.toISOString()}${
      channelId ? `&channel=${channelId}` : ''
    }`,
    requestOptions
  )
    .then(response => response.json())
    .then(data =>
      mapQuickplayEpgData(data, {
        startDate: tempStartDate,
        endDate: tempEndDate
      })
    )
    .catch(error => console.error(error));
};

export const authorizeContent = <T = any>({
  requestUrl,
  oauthToken,
  accessToken,
  xClientId,
  payload
}: {
  requestUrl: string;
  oauthToken: string;
  accessToken: string;
  xClientId: string;
  payload: AuthorizationPlaybackPayload<T>;
}) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${oauthToken}`,
      'X-Authorization': accessToken,
      'X-Client-Id': xClientId,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      delivery: 'streaming',
      quality: 'high',
      ...payload
    }),
    redirect: 'follow'
  } as const;

  return fetch<AuthorizationPlaybackResponse>(requestUrl, requestOptions)
    .then(response => response.json())
    .catch(error => console.error(error));
};

export const authLivePlayback = ({
  requestUrl,
  oauthToken,
  accessToken,
  xClientId,
  deviceName,
  contentId,
  deviceId
}: {
  requestUrl: string;
  oauthToken: string;
  accessToken: string;
  xClientId: string;
  deviceName: string;
  contentId: string;
  deviceId: string;
}) => {
  const payload: AuthorizationPlaybackPayload<any> = {
    deviceName: 'iosmobile',
    deviceId,
    contentId,
    deviceManufacturer: 'Xiaomi',
    deviceModelName: 'MIBOX4',
    deviceOs: 'Android',
    deviceOsVersion: '9',
    proxyDeviceId: '',
    mediaFormat: 'hls',
    catalogType: 'channel',
    contentTypeId: 'live',
    playbackMode: 'live',
    drm: 'fairplay',
    delivery: 'streaming',
    quality: 'medium',
    disableSsai: 'false',
    supportedResolution: 'FHD',
    supportedAudioCodecs:
      'mp4a-latm,ac3,3gpp,amr-wb,dtshd,eac3,ffmpeg,flac,g711-alaw,g711-mlaw,mpeg,opus,raw,vorbis',
    supportedVideoCodecs:
      'x-vnd.on2.vp6a,x-vnd.on2.vp6f,avc,avs,3gpp,hevc,mjpeg,mpeg2,mp4v-es,rm10,rm20,rm30,rm40,vc1,x-vnd.on2.vp8,x-vnd.on2.vp9,wmv3,wmv1,wmv2,x-ms-wmv,wvc1',
    supportedMaxWVSecurityLevel: 'L1'
  };

  return authorizeContent({
    requestUrl,
    oauthToken,
    accessToken,
    xClientId,
    payload
  });
};

export const authVodPlayback = ({
  requestUrl,
  oauthToken,
  accessToken,
  xClientId,
  deviceName,
  contentId,
  deviceId
}: {
  requestUrl: string;
  oauthToken: string;
  accessToken: string;
  xClientId: string;
  deviceName: string;
  contentId: string;
  deviceId: string;
}) => {
  const payload: AuthorizationPlaybackPayload<{
    disableSsai: 'true' | 'false';
    supportedResolution: string;
    supportedAudio: string;
    supportedAudioCodecs: string;
    supportedVideoCodecs: string;
    supportedMaxWVSecurityLevel: string;
  }> = {
    deviceName: 'androidmobile',
    deviceId,
    contentId,
    mediaFormat: 'dash',
    catalogType: 'movie',
    contentTypeId: 'vod',
    playbackMode: 'vod',
    drm: 'widevine',

    // Required for VOD
    disableSsai: 'false',
    supportedResolution: 'QHD',
    supportedAudio: '',
    supportedAudioCodecs:
      'ac3,eac3,mp4a-latm,3gpp,amr-wb,flac,g711-alaw,g711-mlaw,mpeg,opus,raw,vorbis',
    supportedVideoCodecs:
      '3gpp,avc,hevc,mp4v-es,x-vnd.on2.vp8,x-vnd.on2.vp9,av01',
    supportedMaxWVSecurityLevel: 'L1'
  };

  return authorizeContent({
    requestUrl,
    oauthToken,
    accessToken,
    xClientId,
    payload
  });
};

// IMPORTANT: return not full typed Image, it's more a Partial<Image> at the moment
export const extractTileImageUrls = (
  movie: QuickPlayMovieItem,
  swimTemplateName: string
): Image[] => {
  const imgUrls =
    (((movie as any).ia as string[])
      ?.map(format => {
        let url = '';
        let imgType = '';

        switch (format) {
          case IMG_FORMATS.F3x1: {
            // IMPORTANT: this format stretches and renders bad for
            // both portrait and wide
            // imgType = format;
            // url = getImageUrl(movie.id, format, {
            //   height: 315
            // });
            break;
          }
          case IMG_FORMATS.F2x3: {
            imgType = format;
            url = getImageUrl(movie.id, format, {
              height: 537
            });
            break;
          }
          case IMG_FORMATS.F16x9: {
            imgType = format;
            url = getImageUrl(movie.id, format, {
              height: 315
            });
            break;
          }
          default: {
            break;
          }
        }
        return {
          url,
          type: imgType
        };
      })
      ?.filter(img => !!img.url) as Image[]) ?? [];

  // this is based on the swimlane template we got from control
  const sortPriority =
    swimTemplateName ===
    ACCEDO_CONTROL_CONTAINER_TEMPLATES.quickplaySwimlaneWide
      ? IMG_FORMATS.F16x9
      : IMG_FORMATS.F2x3;
  imgUrls.sort((a, b) => {
    if (a.type === sortPriority) {
      return -1;
    }
    if (b.type === sortPriority) {
      return 1;
    }
    return 0;
  });

  return imgUrls;
};

const buildMetadata = (item: QuickPlayMovieItem): string => {
  const genres = item?.log?.[0]?.n?.join(', ') || '';
  const time = `${Math.round(item.rt / 60)} mins`;

  return `${item.r} | ${time} ${genres ? `| ${genres}` : ''}`;
};

const getCredits = (item: QuickPlayMovieItem): any[] => {
  const names = item.locs?.map(loc => loc?.lon?.[0]?.n) || [];

  if (!names.length) {
    return [];
  }

  return [
    {
      names,
      role: 'Cast'
    }
  ];
};

export const fetchMovieVodFeed = async (
  moviesUrl: string,
  query: string,
  swimTemplateName: string
): Promise<QuickPlayMovie[]> => {
  const moviesRaw = await fetch<QuickPlayMovieFeed>(`${moviesUrl}${query}`);
  const moviesData = await moviesRaw.json();
  return moviesData.data.map((movie: QuickPlayMovieItem) => {
    return {
      id: movie.id,
      title: movie?.lon?.[0]?.n || 'Title N.A.',
      description: movie?.lold?.[0]?.n || movie?.lod?.[0]?.n || '',
      images: extractTileImageUrls(movie, swimTemplateName),
      type: 'vod',
      quickplay: true,
      link: `/movie/${movie.id}`,
      backgroundUrl: getImageUrl(movie.id, IMG_FORMATS.F16x9, { width: 1920 }),
      metadata: buildMetadata(movie),
      credits: getCredits(movie)
    };
  });
};
