import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ITEM_SIZE } from '#/components/Item/itemHelper';
import getCardByType from '#/utils/getCardByType';
import { actionClickItem } from '#/services/analytics';
import ContentGrid from '#/components/ContentGrid/DynamicSizeContentGrid';
import { ROUTES } from '#/config/constants';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import useI18n from '#/hooks/useI18n';
import useAppConfig from '#/hooks/useAppConfig';

const getCarouselConfig = ({ itemType, config, carouselWidth }) => {
  const width = carouselWidth || 1880; // 1880 to take padding into consideration (1920-40)
  const spacing = 15;
  const itemSize = ITEM_SIZE[itemType];
  const { width: itemWidth, height: itemHeight } = {
    ...itemSize,
    ...config.itemOptions
  };

  return {
    itemHeight,
    itemWidth,
    spacing,
    width
  };
};

const CarouselShelf = ({
  config = {},
  carouselWidth,
  ds,
  items,
  itemType,
  nav = {},
  onClick
}) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const appConfig = useAppConfig();
  const carouselConfig = getCarouselConfig({
    itemType,
    config,
    carouselWidth
  });
  const Item = getCardByType(itemType);
  const { headPadding = 0, tailPadding = 0 } = config;
  const historyPush = useHistoryPush();

  const onClickQuickplay = useCallback(
    async item => {
      const data = await appConfig.config.quickplay.getVodStream(item.id);
      const { contentUrl: videoUrl, mediaFormat } = data.data;
      // historyPush({
      //   path: ROUTES.player,
      //   state: {
      //     asset: item,
      //     isLive: false,
      //     quickplay: true,
      //     videoUrl
      //   }
      // });

      item.videoUrl = videoUrl;
      item.mediaFormat = mediaFormat;
      onClick(item);
    },
    [appConfig]
  );

  return (
    <ContentGrid
      data={items}
      ds={ds}
      nav={{
        ...nav,
        id: config.id || nav.id,
        useLastFocus: true
      }}
      keyProperty="id"
      itemWidth={carouselConfig.itemWidth}
      itemHeight={carouselConfig.itemHeight}
      horizontal
      headPadding={headPadding}
      tailPadding={tailPadding}
      spacing={carouselConfig.spacing}
      width={carouselConfig.width}
      onClick={item => {
        // TODO: enable details 02 - remove the following lines to allow quickplay items
        // to go to details page, but there is a problem:
        // we do not have a specific api for a single vod item
        // so we may need to re-use the feed api data and find the item by id
        // SOLUTION N1: a solution could be to store only the clicked item metadata
        // in a context at this point
        // SOLUTION N2: another solution could be to pass both the full api and the id to
        // the details page
        // In any case, some customization for Details.js will be needed
        // ----
        if (item.data.quickplay) {
          onClickQuickplay(item.data);
          return;
        }
        // ----
        actionClickItem(item.data, config.displayText);
        return onClick(item.data);
      }}
      DisplayComponent={Item}
      displayComponentProps={{ type: itemType }}
      initialState={{
        // id: items?.[0]?.id,
        position: headPadding
      }}
      // useInternalArrows={useInternalArrows}
      dir={dir}
      buffer={6}
    />
  );
};

CarouselShelf.propTypes = {
  config: PropTypes.object,
  carouselWidth: PropTypes.number,
  ds: PropTypes.object,
  itemType: PropTypes.string,
  items: PropTypes.array,
  nav: PropTypes.object,
  onClick: PropTypes.func
};

export default CarouselShelf;
