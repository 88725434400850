// Retrieving the NPM configuration to be able to expose
// it to the client. Can be useful for displaying the
// application version number.
//
// NOTE: be careful to only expose what you need the
// client to see.
const { title, description, version } = __FILTERED_PACKAGE_JSON__;
const host = __HOST__;

// ovp for webpack.dev.client proxy or the service itself
const accedoOvpUrl = __USE_OVP_PROXY__ ? '/ovp' : __OVP_URL__; // Fallback is defined directly on Webpack config

/*
 *** CLIENT APP CONFIGURATIONS ***
 *
 *  Configurations for the client application.
 */
export default {
  accedoOvpUrl,
  host,
  // Application title, typically to be displayed in a HTML title and
  // in an application header
  title,
  // ApplicationId for quickplay PoC
  controlAppId: '65aea28aa0e84500167ebc14',

  // Application description
  description,
  version,
  // Copyright info (displayed in the footer)
  copyright: '© 2016 - present, Accedo',

  // HTML head information
  head: {
    title,
    titleTemplate: `${title}: %s`,
    meta: [
      { name: 'description', content: description },
      { charset: 'utf-8' },
      { property: 'og:site_name', content: title },
      { property: 'og:image', content: 'vdkweblogo.png' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:card', content: 'summary' },
      { property: 'og:image:width', content: '200' },
      { property: 'og:image:height', content: '200 ' }
    ]
  },

  // Settings for the client side logger
  // These will be passed to the client side winston
  // logger in src/utils/logger.js
  logger: {
    // The default log level.
    // This can be overridden by the transports.
    level: 'debug',

    // Defines the logging targets that we want to log
    // to whenever we're using the logger.
    transports: [
      // Logging to the browser console
      {
        name: 'Console',
        enabled: true
      }
    ]
  },
  application: {
    player: {
      watchlistPercentageThreshold: 98
    }
  },

  defaultScreenDimension: {
    width: 1920,
    height: 1080
  },
  i18n: {
    defaultLocale: 'en'
  }
};
