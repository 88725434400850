import cssVars from 'css-vars-ponyfill';

import {
  HIDE_NOTIFICATION_ANIMATION_TIME,
  SHOW_NOTIFICATION_ANIMATION_TIME
} from '#/interfaces/Notification';

const OPACITIES = {
  high: 0.8,
  medium: 0.5,
  low: 0.2
};

const defaultColor = '#CCCCCC'; // Default color just in case there's no such color on the provided theme, to avoid undefined

const addAlpha = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return (color || defaultColor) + _opacity.toString(16).toUpperCase();
};

/**
 * Parsing the theme to get the values for CSS variables.
 *
 * @param {Object} theme The theme object from the CMS.
 * @returns {Object} The variables to be passed to the Page component.
 */
const setCssVariables = theme => {
  /**
   * Legacy browsers do not accept css variables references withing the code. They cannot update the
   * value for var(--color) at runtime.
   * So we cannot use that apporach
   * Object.keys(style).forEach(key => {
   *  r.style.setProperty(key, style[key]);
   * });
   * We use the cssVars ponyfill to explicit update the color in the style
   * instead of using a variable reference
   *
   */
  const accentHigh = addAlpha(theme.accent, OPACITIES.high);
  const accentMedium = addAlpha(theme.accent, OPACITIES.medium);
  const accentLow = addAlpha(theme.accent, OPACITIES.low);

  const alternativebackgroundHigh = addAlpha(
    theme.alternativeBackground,
    OPACITIES.high
  );
  const alternativebackgroundMedium = addAlpha(
    theme.alternativeBackground,
    OPACITIES.medium
  );
  const alternativebackgroundLow = addAlpha(
    theme.alternativeBackground,
    OPACITIES.low
  );

  const backgroundHigh = addAlpha(theme.background, OPACITIES.high);
  const backgroundMedium = addAlpha(theme.background, OPACITIES.medium);
  const backgroundLow = addAlpha(theme.background, OPACITIES.low);

  const colorOnAccentHigh = addAlpha(theme.colorOnAccent, OPACITIES.high);
  const colorOnAccentMedium = addAlpha(theme.colorOnAccent, OPACITIES.medium);
  const colorOnAccentLow = addAlpha(theme.colorOnAccent, OPACITIES.low);

  const colorOnBackgroundHigh = addAlpha(
    theme.colorOnBackground,
    OPACITIES.high
  );
  const colorOnBackgroundMedium = addAlpha(
    theme.colorOnBackground,
    OPACITIES.medium
  );
  const colorOnBackgroundLow = addAlpha(theme.colorOnBackground, OPACITIES.low);

  const errorHigh = addAlpha(theme.error, OPACITIES.high);
  const errorMedium = addAlpha(theme.error, OPACITIES.medium);
  const errorLow = addAlpha(theme.error, OPACITIES.low);

  const overlayHigh = addAlpha(theme.overlay, OPACITIES.high);
  const overlayMedium = addAlpha(theme.overlay, OPACITIES.medium);
  const overlayLow = addAlpha(theme.overlay, OPACITIES.low);

  const disabledHigh = addAlpha(theme.disabled, OPACITIES.high);
  const disabledMedium = addAlpha(theme.disabled, OPACITIES.medium);
  const disabledLow = addAlpha(theme.disabled, OPACITIES.low);

  cssVars({
    onlyLegacy: true, // default
    variables: {
      // focus color
      '--accent': theme.accent,
      '--accenthigh': accentHigh,
      '--accentmedium': accentMedium,
      '--accentlow': accentLow,
      // skeletons, other backgrounds
      '--alternativebackground': theme.alternativeBackground,
      '--alternativebackgroundhigh': alternativebackgroundHigh,
      '--alternativebackgroundmedium': alternativebackgroundMedium,
      '--alternativebackgroundlow': alternativebackgroundLow,
      // page background
      '--background': theme.background,
      '--backgroundhigh': backgroundHigh,
      '--backgroundmedium': backgroundMedium,
      '--backgroundlow': backgroundLow,
      // text color on focus
      '--coloronaccent': theme.colorOnAccent,
      '--coloronaccenthigh': colorOnAccentHigh,
      '--coloronaccentmedium': colorOnAccentMedium,
      '--coloronaccentlow': colorOnAccentLow,
      // main color for regular text
      '--coloronbackground': theme.colorOnBackground,
      '--coloronbackgroundhigh': colorOnBackgroundHigh,
      '--coloronbackgroundmedium': colorOnBackgroundMedium,
      '--coloronbackgroundlow': colorOnBackgroundLow,
      // error text color
      '--error': theme.error,
      '--errorhigh': errorHigh,
      '--errormedium': errorMedium,
      '--errorlow': errorLow,
      // overlay color
      '--overlay': theme.overlay,
      '--overlayhigh': overlayHigh,
      '--overlaymedium': overlayMedium,
      '--overlaylow': overlayLow,
      // disabled color
      '--disabled': theme.disabled,
      '--disabledhigh': disabledHigh,
      '--disabledmedium': disabledMedium,
      '--disabledlow': disabledLow,

      '--hidenotificationtime': `${HIDE_NOTIFICATION_ANIMATION_TIME}s`,
      '--shownotificationtime': `${SHOW_NOTIFICATION_ANIMATION_TIME}s`
    }
  });
};

export default setCssVariables;
