import React, { useEffect, useState, memo, useCallback } from 'react';
import { withFocus } from '@accedo/vdkweb-navigation';
import { CUSTOM_IMAGE_FORMAT } from '#/components/Item/itemHelper';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import getImageByType from '#/utils/getImageByType';
import { getTranslation } from '#/services/i18n';
import {
  CONTAINER_ITEM_TYPES,
  IMAGE_TYPE,
  ITEM_TYPES
} from '#/config/constants';
import Nav from '#/interfaces/Nav';
import { AssetItemData } from '#/interfaces/AssetItemData';
import style from './assetItem.scss';

interface AssetItem {
  data: AssetItemData;
  nav: Nav;
  onClick: () => any | void;
  type: string;
  width: number | string;
  height: number | string;
}

const CARD_BORDER_WIDTH_PX = 4;

const OrigAssetItem = ({
  data,
  nav,
  onClick,
  type,
  width: widthProp,
  height: heightProp
}: AssetItem) => {
  const [width] = useState(widthProp);
  const [height] = useState(heightProp);
  const [poster, setPoster] = useState('');
  const { viewAll, displayText } = data;

  const loadImage = useCallback(() => {
    if (!type || !data.images?.length) {
      return;
    }

    const imageFormat = CUSTOM_IMAGE_FORMAT[type] || IMAGE_TYPE.POSTER;

    const image = getImageByType(data.images, imageFormat) as string;
    setPoster(image);
  }, [data.images, type]);

  const getBackgroundClassName = (isFocused?: boolean, isViewAll?: boolean) => {
    if (isFocused) {
      if (isViewAll) {
        return `${style.background} ${style.viewAllBackground} ${style.backgroundFocused}`;
      }
      return `${style.background} ${style.backgroundFocused}`;
    }
    if (isViewAll) {
      return `${style.background} ${style.viewAllBackground} `;
    }
    return style.background;
  };

  useEffect(() => {
    loadImage();
  }, [loadImage, data]);

  const typeClassName = style[type] || '';

  const backgroundClassName = getBackgroundClassName(nav.isFocused, viewAll);

  const contentContainerClassName = nav.isFocused
    ? style.contentContainerFocused
    : `${style.contentContainer} ${typeClassName}`;

  const textBoxSize = { width: +width - CARD_BORDER_WIDTH_PX };

  const containerSize = { width, height };

  const imageClassName =
    type === ITEM_TYPES.Square ? `${style['logo-image']}` : '';

  return (
    <div style={containerSize} className={style.container}>
      <div
        className={`link ${style.link}`}
        role="link"
        onClick={onClick}
        aria-label={data.title}
      >
        <div
          className={backgroundClassName}
          onMouseOver={nav.onMouseOver}
          onMouseOut={nav.onMouseOut}
        >
          {!viewAll && (
            <ImageWithPreload
              src={poster}
              className={imageClassName}
              viewAll={viewAll ?? (data.type === 'live' || false)}
            />
          )}

          {viewAll ? (
            <div className={style.viewAll}>
              <div>{getTranslation('viewAll')}</div>
              <div>{displayText}</div>
            </div>
          ) : (
            data.type !== CONTAINER_ITEM_TYPES.Live && (
              <div className={contentContainerClassName} style={textBoxSize}>
                {!viewAll && data.type !== CONTAINER_ITEM_TYPES.Live && (
                  <div className={style.title}>{data.title}</div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const propsAreEqual = (prevProps: AssetItem, nextProps: AssetItem) => {
  const { data: prevData, nav: prevNav } = prevProps || {};
  const { data: nextData, nav: nextNav } = nextProps || {};

  return (
    prevData.title === nextData.title &&
    prevData.displayText === nextData.displayText &&
    prevData.viewAll === nextData.viewAll &&
    prevNav.isFocused === nextNav.isFocused &&
    prevNav.nextleft === nextNav.nextleft &&
    prevNav.nextright === nextNav.nextright &&
    prevNav.nextdown === nextNav.nextdown &&
    prevNav.nextup === nextNav.nextup
  );
};

const AssetItem = withFocus(OrigAssetItem);

export default memo(AssetItem, propsAreEqual);
