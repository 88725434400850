import React, { useState, useEffect } from 'react';
import useAppConfig from '#/hooks/useAppConfig';
import { QuickPlayMovie } from '#/interfaces/quickplay';
import { fetchMovieVodFeed } from '#/utils/quickplay';
import CarouselShelf from '../CarouselShelf/CarouselShelf';

const CarouselQuickplayShelf = ({ ...props }: any) => {
  const [movies, setMovies] = useState<QuickPlayMovie[] | null>(null);
  const appConfig = useAppConfig();

  useEffect(() => {
    const fetchVodMovies = async () => {
      try {
        const moviesUrl =
          appConfig.config.providers.ovp.quickplay.moviesUrl || '';
        const movies = await fetchMovieVodFeed(
          moviesUrl,
          props.query,
          props.config.template
        );
        setMovies(movies);
      } catch (err) {
        console.error('[debug] Unable to fetch quickplay feed', err);
        setMovies([]);
      }
    };

    fetchVodMovies();
  }, []);

  if (movies === null) {
    return <div />;
  }

  return <CarouselShelf {...props} items={movies} />;
};

export default CarouselQuickplayShelf;
