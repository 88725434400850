import React, { useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { focusManager } from '@accedo/vdkweb-navigation';

import { NOTIFICATION_TYPES } from '#/interfaces/Notification';
import PointerContextProvider from '#/context/PointerContext';
import { PageProvider } from '#/context/PageContext';
import SplashScreen from '#/components/SplashScreen/SplashScreen';
import Popup from '#/components/Popup/Popup';
import { getTranslation } from '#/services/i18n';
import useAppConfig from '#/hooks/useAppConfig';
import useNetworkStatus from '#/hooks/useNetworkStatus';
import useAuthProfile from '#/hooks/useAuthProfile';
import useI18n from '#/hooks/useI18n';
import useExtraStyleClasses from '#/hooks/useExtraStyleClasses';
import useNotificationSystem from '#/hooks/useNotificationSystem';
import Loader from '#/components/Loader/Loader';

import defaultLocalTheme from '#/theme/defaultTheme.json';
import setCssVariables from '#/utils/setCssVariables';

import useProfile from '#/hooks/useProfile';
import styles from './tvApp.scss';

const MAINTENANCE = 'maintenance';

// The entry point of your application. This is where
// you can control rendering based on received application
// status or configurations.
const TVAppContent = ({ platform, children }) => {
  const prevNetworkStatus = useRef(true);
  const {
    checkAuthStatus,
    loadCurrentProfile,
    loginStartup
  } = useAuthProfile();
  const [state, setState] = useState({
    user: undefined
  });
  const { config } = useAppConfig();
  const { extraClasses } = useExtraStyleClasses();
  const { currentLocale = {} } = useI18n();
  const { code: localeCode } = currentLocale;
  const { getProfiles, setCurrentProfile } = useProfile();

  const { networkStatus } = useNetworkStatus();
  const { addNotification, hideNotification } = useNotificationSystem();

  useEffect(() => {
    setCssVariables(config?.theme || defaultLocalTheme);
  }, [config]);

  const networkErrorNotification = useRef();

  const onStartUp = useCallback(async () => {
    // TODO: dev here your custom logic for the startup
    const user = await checkAuthStatus();
    if (!user) {
      const startupUser = await loginStartup();
      if (!startupUser) {
        // Keep retrying
        return onStartUp();
      }

      const profiles = await getProfiles();
      await setCurrentProfile(profiles[0]);

      await loadCurrentProfile();
      return startupUser;
    }
    // console.log('[DEBUG] User:', user);
    await loadCurrentProfile();
    return user;
  }, [checkAuthStatus, loadCurrentProfile, loginStartup]);

  useEffect(() => {
    if (networkStatus === false) {
      networkErrorNotification.current = addNotification({
        timer: 0,
        type: NOTIFICATION_TYPES.error,
        title: getTranslation('networkErrorLoadingViewTitle'),
        description: getTranslation('networkErrorLoadingViewContent')
      });
    } else if (prevNetworkStatus.current === false) {
      addNotification({
        timer: 10,
        type: NOTIFICATION_TYPES.success,
        title: getTranslation('networkRecoveryTitle'),
        description: getTranslation('networkRecoveryContent')
      });
      setTimeout(() => {
        hideNotification(networkErrorNotification.current);
      }, 500); // To avoid animation combos that affect UX
    }
    prevNetworkStatus.current = networkStatus;
  }, [networkStatus, addNotification, hideNotification]);

  useEffect(() => {
    const getUser = async () => {
      const user = await onStartUp();
      setState({ user });
    };
    if (!config.status && state.user === undefined) {
      getUser();
    }
  }, [config.status, state.user, onStartUp]);

  useEffect(() => {
    if (config.status === 'Active' && state.user !== undefined) {
      focusManager.setPersistTrail(true);
    }
  }, [config, state.user]);

  // Remove the splash screen
  useEffect(() => {
    if (config.status === 'Active' && Object.keys(config).length > 0) {
      const splash = document.getElementById('splash');
      splash?.remove?.();
    }
  }, [config, localeCode]);

  if (config?.errorLoading) {
    focusManager.changeFocus('ERROR_POPUP');
    return (
      <Popup
        nav={{
          id: 'ERROR_POPUP'
        }}
        isOpen
        isAppInitError
        title={getTranslation('configErrorLoadingTitle')}
        content={<span>{getTranslation('configErrorLoadingContent')}</span>}
        onAccept={() => {
          // clear browser history
          if (window.history.replaceState) {
            window.history.replaceState(null, null, window.location.origin);
          }
          // reload page, the url only contains origin, no query parameters
          window.location = window.location.origin;
        }}
        theme={styles}
        acceptText={getTranslation('okButton')}
      />
    );
  }

  // If we haven't yet received an application status, we'll
  // simply return the App Loader.
  if (!localeCode || state.user === undefined) {
    return (
      <div className={`${styles.root} ${platform}`}>
        <Loader />
      </div>
    );
  }

  // We require the configuration to have been
  // provided before we render the actual app content.
  // simply return the App Loader.
  // This cases applies also when the app switch from one segment type to another (ie: Kids profile)
  if (!Object.keys(config).length) {
    return (
      <div className={`${styles.root} ${platform}`}>
        <Loader />
      </div>
    );
  }

  // If we're in maintenance mode, we'll display
  // the given message or fall back to a default one.
  if (config.status === MAINTENANCE) {
    return <SplashScreen status={SplashScreen.status.MAINTENANCE} />;
  }

  // TODO: uncomment if you want to make the login blocking the app
  // if (this.context.isCheckingAuth) {
  //   return (
  //     <SplashScreen status={SplashScreen.status.NO_CONDATA_LOADINGFIG} />
  //   );
  // }

  // At this point we have both an active status and
  // a configuration object. Let's render the app!
  const extraClassesString = extraClasses
    ?.map(extraClass => styles[extraClass])
    ?.join(' ');
  return (
    <PageProvider>
      <PointerContextProvider>
        <div className={`${styles.root} ${platform} ${extraClassesString}`}>
          {children}
        </div>
      </PointerContextProvider>
    </PageProvider>
  );
};

TVAppContent.propTypes = {
  platform: PropTypes.string,
  children: PropTypes.node
};

export default TVAppContent;
